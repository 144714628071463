// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-artwork-js": () => import("./../../../src/pages/artwork.js" /* webpackChunkName: "component---src-pages-artwork-js" */),
  "component---src-pages-collections-js": () => import("./../../../src/pages/collections.js" /* webpackChunkName: "component---src-pages-collections-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-poems-js": () => import("./../../../src/pages/poems.js" /* webpackChunkName: "component---src-pages-poems-js" */),
  "component---src-templates-poem-js": () => import("./../../../src/templates/poem.js" /* webpackChunkName: "component---src-templates-poem-js" */)
}

